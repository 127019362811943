import React, { useContext } from 'react'
import Link from 'components/Link'
import { useCompanyDetail } from '../../DataProvider'
import Image from 'next/image'
import { JobsTag } from '../SearchPanel'
import { languageContext } from 'app/components/providers/languageProvider'
import { Salary } from '../Salary'

import style from './jobcard.module.scss'

export const OpeningJobCard = (props: {
  salary_info: Record<string, any>
  job_title: string
  job_url: string
  job_tags: string[]
  recruiter: Record<string, any>
}) => {
  const { lang } = useCompanyDetail()
  const { job_title, salary_info, job_url, job_tags, recruiter = {} } = props as any
  const contextLang = useContext(languageContext)
  const { overview } = contextLang.companyDetail
  const link = '/' + lang + job_url

  return (
    <div className={style.card_container}>
      <div className={style.card_left}>
        <Link className={style.card_title} href={link} target='_blank' title={job_title}>
          {job_title}
        </Link>
        <div className={style.card_content}>
          <div className={style.card_salary}>
            <Salary salaryType={salary_info.type_text} salaryRange={salary_info.text} />
          </div>
          <div className={style.card_info_list}>
            <JobsTag data={job_tags} count={3} />
          </div>
        </div>
      </div>
      <div className={style.card_chat_container}>
        <div className={style.card_chat_wrapper}>
          <Image
            src={recruiter?.avatar}
            className={style.recruiter_avatar}
            width={40}
            height={40}
            alt={'alt'}
          />
          <div className={style.card_chat_content}>
            <div className={style.card_chat_title}>{recruiter?.full_name}</div>
            <div className={style.card_chat_name}>{recruiter?.job_title}</div>
            <Link href={link + '?source=company_detail'} target='_blank'>
              <div className={style.chat_now}>{overview.jobs.card.chatNow}</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}


