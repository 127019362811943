'use client'
import React, { useState, useLayoutEffect, useRef, useContext } from 'react'
import style from './index.module.scss'
import styles from '../SearchPanel/index.module.scss'
import Section from '../Section/index'
import Map from 'app/(job)/[lang]/components/Main/Map/Map'
import { OpeningJobCard } from '../OpeningJobCard/index'
import { languageContext } from '../../../../../../components/providers/languageProvider'
import { useCompanyDetail } from '../../DataProvider'
import Image from 'next/image'
import classNames from 'classnames'
import Link from 'components/Link'
import { useMediaQuery } from '@mui/material'
import { SocialMedia, TagContent, NewsCom } from '../Culture'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ChatItem } from '../ChatPanel'
import 'swiper/swiper.min.css'
import Lightbox from 'react-image-lightbox'
import Tooltip from '@mui/material/Tooltip'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import BusinessInformationExplanation from '../BusinessInformationExplanation'
import CompanyBrandList from '../CompanyBrandList'
import BrandSwiper from 'app/components/companyBrand/brandSwiper'
import { Preview } from '../Preview'
interface Props extends React.PropsWithChildren<any> {
  // jobs: JobData[]
  onMore: (e: React.SyntheticEvent) => void
  isMobile: Boolean
}

export const padArrayToMultiple = (arr) =>
  function <T>(num: number) {
    const length = arr.length
    const remainder = length % num
    if (remainder === 0) {
      return arr // Array length is already a multiple of num
    }
    const paddingLength = num - remainder
    const padding = new Array(paddingLength).fill(null)
    return arr.concat(padding) as T[]
  }

function Introduction(
  index: number,
  item: { id: string; title: string },
  noSplit: boolean,
  description
): JSX.Element {
  const ref = useRef(null)
  const sectionRef = useRef(null) // 用于存储 <Section> 元素的引用
  const [isVisible, setIsVisible] = useState(false)
  const [contentHeight, setContentHeight] = useState(150)
  const calculateContentHeight = () => {
    setContentHeight(ref.current?.scrollHeight)
  }
  const [showMore, setShow] = useState(false)

  useLayoutEffect(() => {
    calculateContentHeight()
    if (isContentOverflowing(ref.current)) {
      setShow(true)
    }
  })
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // const target = event.target as HTMLElement
    if (!isVisible && !document.getElementById('toggleVisible')) {
      return
    }
    if (isVisible) {
      // 滚动到 <Section> 顶部
      window.scrollTo(0, 0)
      // if (sectionRef.current) {
      //   const offsetTop = sectionRef.current.getBoundingClientRect().top + window.scrollY - 57 - 20
      // window.scrollTo({
      //   top: 0,
      //   behavior: 'smooth'
      // })
      // }
    }
    setIsVisible(!isVisible)
  }

  const contextLang = useContext(languageContext)
  const { overview } = contextLang.companyDetail
  if (!description) return null
  return (
    <div ref={sectionRef}>
      <Section key={index} title={item.title} split={true}>
        <div
          className={classNames({
            [style.introduce_wrapper]: true
          })}
        >
          {isVisible ? (
            <div
              onClick={handleClick}
              className={style.introduction}
              ref={ref as React.RefObject<HTMLDivElement>}
              dangerouslySetInnerHTML={{
                __html: filterScriptContent(`${description}`)
              }}
            ></div>
          ) : (
            <div onClick={handleClick}>
              <HTMLEllipsis
                unsafeHTML={filterScriptContent(`${description}`)}
                maxLine={5}
                ellipsisHTML={`... <span id="toggleVisible" style="color:#2378E5;cursor:pointer" >${overview.More}</span>`}
                basedOn='letters'
              />
            </div>
          )}
        </div>

        {isVisible && (
          <div className={`${style.more} ${style.moreInfo}`} onClick={handleClick}>
            {isVisible ? overview.Less : overview.More}
          </div>
        )}
      </Section>
    </div>
  )
}

function BusinessInfo({
  index,
  item,
  noSplit
}: {
  index: number
  item: { id: string; title: string }
  noSplit: boolean
}): JSX.Element {
  const { companyPageData } = useCompanyDetail()
  const info = companyPageData?.company_info?.company_business_info || {}
  const businessInfo = info?.business_info || {}

  const business_info = [
    'legal_name',
    'founding_data',
    'operating_status',
    'social_credit_code',
    ['company_type', 'company_type_name_title'],
    'registered_capital',
    'country',
    'founder',
    'registered_address',
    'ceo'
  ]
    .map((field) => {
      let key = Array.isArray(field) ? field[0] : field
      let titleKey = Array.isArray(field) ? field[1] : `${key}_title`
      return {
        name: businessInfo[key],
        field: businessInfo[titleKey]
      }
    })
    .filter((v) => Boolean(v.field && v.name))

  const [isVisible, setIsVisible] = useState(false)
  const [contentHeight, setContentHeight] = useState(150)
  const [isShowTips, setIsShowTips] = useState(false)
  const handleClick = () => {
    setIsVisible(!isVisible)
  }
  const contentRef = useRef(null)
  const calculateContentHeight = () => {
    setContentHeight(contentRef.current?.scrollHeight)
  }
  useLayoutEffect(() => {
    calculateContentHeight()
  })
  const isMobile = useMediaQuery('(max-width: 768px)')

  const contextLang = useContext(languageContext)
  const { overview } = contextLang.companyDetail

  const _resArr = business_info //.filter(v => Boolean(v.field))
  const showMore = _resArr.length > 6
  if (!_resArr.length) {
    return null
  }

  return (
    <Section
      key={index}
      title={info.title + ' '}
      id={item.id}
      split={!noSplit}
      onHandelClose={() => setIsShowTips(true)}
    >
      <BusinessInformationExplanation
        title={info.tips_title}
        content={info.tips_text}
        isShowTips={isShowTips}
        onClose={() => setIsShowTips(false)}
      />
      <div className={`${item.id === 'Business information' ? style.BusinessInformation : null}`}>
        <div
          className={style.animation_wrapper}
          style={{
            height: !isVisible ? (!showMore ? 'auto' : 128) : contentHeight
          }}
        >
          <div className={style.overview_item_wrapper} ref={contentRef}>
            {_resArr.map((item) => {
              const value = item.name // props[item?.field]
              let title = item?.field
              const is_url = isURL(value)

              return (
                <div key={item?.field} className={style.business_item}>
                  <div className={style.overview_item_name}>{title}</div>
                  {item && !isMobile && (
                    <Tooltip title={value} placement='top-start' arrow>
                      <p
                        style={{
                          width: '100%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {is_url ? (
                          <Link
                            href={value}
                            className={style.overview_item_value_PC}
                            target={'_blank'}
                          >
                            {value}
                          </Link>
                        ) : (
                          value
                        )}
                      </p>
                    </Tooltip>
                  )}
                  {(() => {
                    if (item && isMobile && is_url)
                      return (
                        <Link
                          href={value}
                          className={style.overview_item_value_mobile}
                          target={'_blank'}
                          style={{ color: '#2378E5' }}
                        >
                          {value}
                        </Link>
                      )
                    if (item && isMobile)
                      return <div className={style.overview_item_value_mobile}>{value}</div>
                  })()}
                </div>
              )
            })}
          </div>
        </div>
        {showMore && (
          <div className={style.containerMore}>
            <div
              className={`${style.more} ${isVisible ? style.lessMore : ''}`}
              onClick={handleClick}
            >
              {isVisible ? overview.Less : overview.More}
            </div>
          </div>
        )}
      </div>
    </Section>
  )
}

export function filterScriptContent(str: string): string {
  const regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
  return str.replace(regex, '')
}

export function isURL(str) {
  // Regular expression pattern to match a URL
  const urlPattern = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/

  // Test the string against the pattern
  return urlPattern.test(str)
}

function isContentOverflowing(element) {
  return (
    element?.scrollWidth > element?.clientWidth || element?.scrollHeight > element?.clientHeight
  )
}

export function MobileHiBoss(overview) {
  const { companyPageData } = useCompanyDetail()
  const [recruiterId, setRecruiterId] = useState(null)
  const companyInfo = companyPageData?.company_info || {}
  let hr = companyInfo?.company_members?.members || []

  return (
    <div className={styles.filter_container}>
      {hr.map((item, index) => {
        return (
          <div key={index}>
            <ChatItem {...item} setRecruiterId={setRecruiterId}></ChatItem>
          </div>
        )
      })}
      {recruiterId && (
        <Preview Id={recruiterId} setRecruiterId={setRecruiterId} overview={overview}></Preview>
      )}
    </div>
  )
}

function MobileAlbum(pictures) {
  const [isOpenLightbox, setLightbox] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const res = pictures
  if (!res?.length) return null
  return (
    <div>
      <Swiper spaceBetween={30} slidesPerView={1.2} scrollbar={{ draggable: true }}>
        {res.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div className={style.mobile_album}>
                <Image
                  style={{ objectFit: 'cover' }}
                  fill
                  src={item.url}
                  alt='album'
                  onClick={() => {
                    setPhotoIndex(index)
                    setLightbox(true)
                  }}
                ></Image>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      {isOpenLightbox && (
        <Lightbox
          mainSrc={res[photoIndex].url}
          nextSrc={res[(photoIndex + 1) % res.length].url}
          prevSrc={res[(photoIndex + res.length - 1) % res.length].url}
          onCloseRequest={() => setLightbox(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex) => (photoIndex + res.length - 1) % res.length)
          }
          onMoveNextRequest={() => setPhotoIndex((photoIndex) => (photoIndex + 1) % res.length)}
        />
      )}
    </div>
  )
}

const CompanyInfo = (_props: Props) => {
  const props = { ..._props }
  const { companyPageData, jobs } = useCompanyDetail()
  const isMobile = props.isMobile

  // console.log('companyPagecompanyPage', companyPageData)
  const [isOpenLightbox, setLightbox] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const brandProductList =
    companyPageData.company_info?.company_brand_product?.brand_and_product_info || []
  const contextLang = useContext(languageContext)
  const { overview } = contextLang.companyDetail
  const companyInfo = companyPageData.company_info || {}

  const info = [
    {
      id: 'Introduction',
      title: companyInfo?.company_description?.title // overview.Introduction
    },
    {
      id: 'Company Album',
      title: companyInfo?.company_album?.title //overview.CompanyAlbum
    },
    {
      id: 'Brand or Product',
      title: companyInfo?.company_brand_product?.title //overview.BrandorProduct
    },
    {
      id: 'Business information',
      title: companyInfo?.company_business_info?.title //overview.BusinessInformation
    },
    {
      id: 'Address',
      title: companyInfo?.company_addr?.title //contextLang.myJobs.address
    },

    {
      id: 'Job openings',
      title: companyInfo?.company_job_openings?.title //overview.JobOpenings
    }
  ]

  const desc = companyInfo?.company_description || {}
  const pictures = companyInfo?.company_album?.albums || []
  const { company_members, company_news } = companyInfo || {}

  if (isMobile) {
    const cultures = companyInfo?.company_culture || {}
    const benefits = companyInfo?.company_benefits || {}
    const { addr, longitude, latitude } = companyInfo?.company_addr || {}
    const companyAlbums = companyInfo?.company_album?.albums || []

    return (
      <div className={style.tab_content_wrapper}>
        {desc?.description &&
          desc.description != '-' &&
          Introduction(0, info[0], true, desc.description)}
        {companyAlbums?.length > 0 && (
          <Section title={info[1].title}>{MobileAlbum(companyAlbums)}</Section>
        )}
        <BrandSwiper
          title={companyInfo?.company_brand_product?.title}
          brandProduct={brandProductList}
          pageType='company-detail'
        />
        <BusinessInfo index={4} item={info[3]} noSplit={true} />
        {cultures?.culture?.length > 0 && (
          <Section title={cultures.title}>
            <TagContent type={'culture'} data={cultures.culture}></TagContent>
          </Section>
        )}
        {benefits?.benefits?.length > 0 && (
          <Section title={benefits.title}>
            <TagContent type={'benefits'} data={benefits.benefits} />
          </Section>
        )}
        {company_members?.members?.length > 0 && (
          <Section title={overview.HiBoss}>{MobileHiBoss(overview)}</Section>
        )}
        {addr && (
          <Section title={info[4].title}>
            <div id='mapData' style={{ display: 'none' }}>
              {JSON.stringify({
                latitude: Number(latitude),
                longitude: Number(longitude),
                address: addr
              })}
            </div>
            <Map
              data={{
                latitude: Number(latitude),
                longitude: Number(longitude),
                address: addr
              }}
              lang={contextLang.jobDetail}
            />
          </Section>
        )}
        {<SocialMedia />}
        {company_news?.data?.length > 0 && <NewsCom news={company_news} />}
      </div>
    )
  }

  return (
    <div className={style.tab_content_wrapper}>
      {info.map((item, index) => {
        const noSplit = index === 0
        if (item.id === 'Introduction' && desc?.description && desc?.description != '-') {
          return Introduction(index, item, noSplit, desc.description)
        }

        if (item.id === 'Company Album' && companyInfo?.company_album?.albums?.length > 0) {
          return (
            <>
              <Section key={index} title={item.title} split={!noSplit}>
                <div className={style.album_wrapper}>
                  {padArrayToMultiple(
                    (companyInfo?.company_album?.albums || []).sort(
                      (a, b) => a.sort_order - b.sort_order
                    )
                  )(3).map((item, index) => {
                    if (!item)
                      return (
                        <div className={style.album_item} style={{ width: 220, height: 150 }}></div>
                      )
                    return (
                      <Image
                        key={index}
                        src={item.url}
                        alt='alt'
                        className={style.album_item}
                        onClick={() => {
                          setPhotoIndex(index)
                          setLightbox(true)
                        }}
                        width={220}
                        height={134}
                        style={{ objectFit: 'cover' }}
                      />
                    )
                  })}
                </div>
              </Section>
              {isOpenLightbox && (
                <Lightbox
                  mainSrc={pictures[photoIndex].url}
                  nextSrc={pictures[(photoIndex + 1) % pictures.length].url}
                  prevSrc={pictures[(photoIndex + pictures.length - 1) % pictures.length].url}
                  onCloseRequest={() => setLightbox(false)}
                  onMovePrevRequest={() =>
                    setPhotoIndex(
                      (photoIndex) => (photoIndex + pictures.length - 1) % pictures.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex) => (photoIndex + 1) % pictures.length)
                  }
                />
              )}
            </>
          )
        }

        if (
          item.id === 'Brand or Product' &&
          companyInfo?.company_brand_product?.brand_and_product_info?.length
        ) {
          return (
            <Section key={index} title={item.title} split={true}>
              <CompanyBrandList />
            </Section>
          )
        }
        if (
          item.id === 'Business information' &&
          companyInfo?.company_business_info?.business_info
        ) {
          return <BusinessInfo index={index} item={item} noSplit={noSplit} />
        }
        if (item.id === 'Address' && companyInfo?.company_addr) {
          const { addr, longitude, latitude } = companyInfo?.company_addr || {}
          if (!addr) {
            return null
          }

          return (
            <Section key={index} title={item.title} split={!noSplit}>
              <div id='mapData' style={{ display: 'none' }}>
                {JSON.stringify({
                  latitude: Number(latitude),
                  longitude: Number(longitude),
                  addres: addr
                })}
              </div>
              <Map
                data={{
                  address: addr,
                  latitude: Number(latitude),
                  longitude: Number(longitude)
                }}
                lang={contextLang.jobDetail}
              />
            </Section>
          )
        }
        const { title, more_text } = companyInfo?.company_job_openings || {}
        let newJobs = (jobs as any)?.company_jobs?.company_hot_job || []
        if (item.id === 'Job openings' && newJobs?.length) {
          return (
            <Section key={index} title={title}>
              {newJobs.slice(0, 5).map((item, index) => {
                return <OpeningJobCard item={item} {...item} key={index}></OpeningJobCard>
              })}
              {newJobs?.length > 5 && (
                <div className={style.containerMore}>
                  <div className={style.more} onClick={props.onMore}>
                    {more_text}
                  </div>
                </div>
              )}
            </Section>
          )
        }
      })}
    </div>
  )
}

export default CompanyInfo
