import { cache } from 'react'
import configuredAxios from 'helpers/configuredAxios'

// export const fetchSeo = cache(async (lang, jobId) => {
//   const axios = await configuredAxios('apiGo')
//   const seoData = await axios.get(`/web/job_detail_seo/${jobId}?lang=${lang}`)
//     .then(res => res.data.data)

//   return seoData
// })

export const fetchCompanyPageData = cache(async (companyId, lang) => {
  const axios = await configuredAxios('apiGo')
  // /api-go/web/company_detail/31491?lang=zh-CN
  const data = await axios.get(`web/company_detail/${companyId}?lang=${lang}`)
    .then(res => res.data.data)

  return data
})

export const fetchCompanyRemoteJobs = cache(async (companyId, lang, params = {}) => {
  let searchparams = new URLSearchParams({ lang, ...params }).toString() || ''
  const axios = await configuredAxios('apiGo')
  // /api-go/web/company_remote_job_detail/31353?lang=zh-CN
  const data = await axios.get(`web/company_remote_job_detail/${companyId}?${searchparams}`)
    .then(res => res.data.data)
  return data
})

export const fetchCompanyJobs = cache(async (companyId, lang, params = {}) => {
  let searchparams = new URLSearchParams({ lang, ...params }).toString() || ''

  const axios = await configuredAxios('apiGo')
  // //api-go/web/company_job_detail/31476?lang=zh-CN
  const data = await axios.get(`web/company_job_detail/${companyId}?${searchparams}`)
    .then(res => res.data.data)

  return data
})


export const v2CanSearchFetchCompanyJobs = cache(async (companyId, lang, params = {}) => {
  let searchparams = new URLSearchParams({ lang, ...params }).toString() || ''

  const axios = await configuredAxios('apiGo')
  // //api-go/web/company_job_detail/31476?lang=zh-CN
  const data = await axios.get(`/web/company_detail_jobs/${companyId}?${searchparams}`)
    .then(res => res.data.data)

  return data
})

export const fetchRecruiterDetail = cache(async (recruiterId, lang,) => {
  // let searchparams = new URLSearchParams({ lang, ...params }).toString() || ''

  const axios = await configuredAxios('apiGo')
  // //api-go/web/company_job_detail/31476?lang=zh-CN
  const data = await axios.get(`/web/recruiters/${recruiterId}/recruiter_info?lang=${lang}`)
    .then(res => res.data.data)

  return data
})


