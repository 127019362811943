'use client'
import React, { useMemo } from 'react'
import style from './index.module.scss'
import Image from 'next/image'
import { useCompanyDetail } from './DataProvider'
import useResponsiveFont from 'app/(talents)/[lang]/talents/components/responseHook'

import CompanyInfo from './components/InfoList'
import { languageContext } from 'app/components/providers/languageProvider'
import { MobileCard } from './components/MobileCard'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  )
}
const MobilePageHeader = ({ name, tags, logo_url }) => {
  return (
    <div className={style.mobileHeader}>
      <div className={style.info}>
        <div>
          <h1>{name}</h1>
          <div className={style.subtitle}>
            {tags.filter(Boolean).map((value, index) => {
              return (
                <span key={index} className={style.item}>
                  {value}
                </span>
              )
            })}
          </div>
        </div>

        <div className={style.logo}>
          <Image width={56} height={56} src={logo_url} alt='_' />
        </div>
      </div>
    </div>
  )
}

const MobilePage = () => {
  const [value, setValue] = React.useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const {
    // detail,
    jobs = {},
    // jobFunctions,
    // jobClassesRemote,
    // locationRemote,
    // languageRemote,
    // remoteJobs,
    companyPageData
  } = useCompanyDetail()

  // const { function_filter } = jobs

  // jobClassesRemote={jobClassesRemote}
  // locationRemote={locationRemote}
  // languageRemote={languageRemote}
  // remoteJobs={remoteJobs}
  // isRemoteWorldwide={is_remote_worldwide}

  console.log(jobs, 'jobs')

  const contextLang = React.useContext(languageContext)
  const { tab } = contextLang.companyDetail
  useResponsiveFont(390, 767)
  const headerData = companyPageData?.company_header || {}
  const salary = useMemo(() => {
    let results = {
      monthly: [],
      daily: [],
      annually: [],
      hourly: []
    }
    let typeList = ((jobs as any)?.salary_tags || []).map((v) => {
      const seo = v['seo_value'] || v['seo-value']
      let type = {
        id: v.salary_type_id,
        value: v.salary_type,
        'seo-value': seo,
        seo_value: seo,
        children: v.salary_range_list
      }
      let rangeList = (v?.salary_range_list || []).map((v) => {
        return { ...v }
      })
      let typeLabel = 'monthly'
      if (type.id === 2) {
        typeLabel = 'daily'
      }
      if (type.id === 3) {
        typeLabel = 'hourly'
      }
      if (type.id === 4) {
        typeLabel = 'annually'
      }
      results[typeLabel] = rangeList
      return type
    })
    return { typeList, typeRange: results }
  }, [(jobs as any)?.salary_tags])

  return (
    <div className={style.container}>
      <MobilePageHeader
        name={headerData.name}
        tags={headerData.company_tags || []}
        logo_url={headerData.logo_url}
      />
      <TabPanel value={value} index={0}>
        <CompanyInfo
          isMobile={true}
          // jobs={jobs.jobs || []}
          onMore={(e) => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
            setTimeout(() => {
              handleChange(e, 1)
            }, 300)
          }}
        ></CompanyInfo>
      </TabPanel>
      <MobileCard
        salaryTypeLists={salary.typeList}
        salaryList={salary.typeRange.monthly}
        dailySalaryRangeFilters={salary.typeRange.daily}
        hourlySalaryRangeFilters={salary.typeRange.hourly}
        annuallySalaryRangeFilters={salary.typeRange.annually}
        // locationRemote={locationRemote}
        // languageRemote={languageRemote}
        jobs={jobs}
        // jobClassesRemote={jobClassesRemote}
        // remoteJobs={remoteJobs}
        tab={tab}
      />
    </div>
  )
}

export default MobilePage
